import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import ForgotPassword from "@/views/ForgotPassword";
import Dashboard from "@/views/Dashboard";
import DashboardStudent from "@/views/DashboardStudent";
import Register from "@/views/Register";
import PageNotFound from "@/views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/private/:token?",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "home",
        component: () => import("@/views/Home"),
      },
      {
        path: "accounting",
        component: () => import("@/views/Accounting"),
      },
      {
        path: "pay-students-status",
        component: () => import("@/views/AccountingStudent"),
      },
      {
        path: "personal",
        component: () => import("@/views/Personal"),
      },
      {
        path: "cash_register",
        component: () => import("@/views/CashRegister"),
      },
      {
        path: "course",
        component: () => import("@/views/Course"),
      },
      {
        path: "student",
        component: () => import("@/views/Student"),
      },
      {
        path: "diagnostic-results",
        component: () => import("@/views/DiagnosticsResults"),
      },
      // Teacher
      // {
      //   path: 'my_courses',
      //   component: () => import('@/views/MyCourses'),
      // },
      {
        path: "course/:id",
        component: () => import("@/views/Course/Students.vue"),
      },
      // Catalogs
      {
        path: "languages",
        component: () => import("@/views/Language"),
      },
      {
        path: "classroom",
        component: () => import("@/views/Classroom"),
      },
      {
        path: "cycle",
        component: () => import("@/views/Cycle"),
      },
      {
        path: "book",
        component: () => import("@/views/Book"),
      },
      {
        path: "program",
        component: () => import("@/views/Program"),
      },
      {
        path: "schedule",
        component: () => import("@/views/Schedule"),
      },
      {
        path: "type_document",
        component: () => import("@/views/TypeDocument"),
      },
      {
        path: "payment",
        component: () => import("@/views/Payment"),
      },
      {
        path: "cycles_payment",
        component: () => import("@/views/CyclesPayment"),
      },
    ],
  },
  {
    path: "/portal",
    name: "DashboardStudent",
    component: DashboardStudent,
    children: [
      {
        path: "home",
        component: () => import("@/views/StudentDashboard/Home.vue"),
      },
      {
        path: "payment_history",
        component: () => import("@/views/StudentDashboard/PaymentHistory.vue"),
      },
      {
        path: "my_payments",
        component: () => import("@/views/StudentDashboard/MyPayments.vue"),
      },
      {
        path: "proceedings",
        component: () => import("@/views/StudentDashboard/Proceedings"),
      },
    ],
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot_password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
