<template>
  <v-app v-if="auth">
    <!-- AppBar -->
    <v-app-bar app color="amarillo" flat fixed clipped-left>
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="pl-0">
        <router-link to="/portal/home">
          <v-img src="@/assets/logotipo-negro.png" width="175"></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="text-lowercase black--text"
            v-bind="attrs"
            v-on="on"
          >
            {{ user.email }}
            <v-icon right>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Tema Oscuro</v-list-item-title>
            <v-list-item-action>
              <v-switch v-model="$vuetify.theme.dark" color="blue"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="dialogConfirm = true">
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Manu Drawer -->
    <v-navigation-drawer v-model="sidebarMenu" dark color="azul" app clipped>
      <v-list>
        <v-list-item>
          <v-avatar size="96" class="avatar-content">
            <v-img
              v-if="user.photo_img"
              class="text-center"
              :src="img + 'student/' + user.photo_img"
            />
          </v-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-center">
              {{ user.first_name }}
              <br />
              {{ user.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-center">
              Estudiante
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-center">
              Codigo: <b> {{ user.code }} </b>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            link
            :to="item.href"
            color="white"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.text"
                class=""
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group
          v-for="item in itemsStudent"
          :key="item.text"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="child in item.items"
            :key="child.text"
            link
            :to="child.href"
            color="white"
          >
            <v-list-item-title v-text="child.text"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Content -->
    <v-main>
      <transition name="fade">
        <router-view></router-view>
      </transition>

      <Snackbard />

      <v-dialog v-model="dialogConfirm" max-width="270px">
        <v-card>
          <v-card-title class="tex-center">
            <v-icon color="primary" left>mdi-progress-question</v-icon>
            Información
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mb-0 mt-5 black--text">¿Desea cerrar sesión?</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete()">
              Cancelar
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="logOut()"
              :loading="isLoading"
            >
              Cerrar sesión</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>

    <!-- Footer -->
    <v-footer>
      <Copyright />
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Snackbard from "../components/Snackbar";
import Copyright from "../components/Copyright";
export default {
  components: { Snackbard, Copyright },
  name: "DashboardStudent",
  data: () => ({
    auth: false,
    sidebarMenu: true,
    dialogConfirm: false,
    isLoading: false,
    selectedItem: 0,
    items: [
      {
        text: "Inicio",
        href: "/portal/home",
        icon: "mdi-chair-school",
      },
    ],
    itemsStudent: [
      {
        action: "mdi-credit-card-outline",
        text: "Pagos",
        items: [
          {
            text: "Realizar Pagos",
            href: "/portal/my_payments",
            icon: "mdi-credit-card-settings-outline",
          },
          {
            text: "Historial de Pago",
            href: "/portal/payment_history",
            icon: "mdi-format-list-bulleted",
          },
        ],
      },
    ],
  }),
  mounted() {
    this.welcome();
  },
  methods: {
    ...mapActions({
      getStudent: "Student/getStudent",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    welcome: function() {
      let token = undefined;
      if (this.$route.params.token) {
        token = this.$route.params.token;
        this.$store.commit("setToken", token);
        sessionStorage.setItem("language_student_token", token);
        this.$router.push("/private");
      } else {
        //aqui deberia pedir ver si el token que tengo en local sesion storage existe en usuarios.
        if (!this.$store.token) {
          token = sessionStorage.getItem("language_student_token");
          if (token) {
            this.$store.commit("setToken", token);
          }
        }
      }
      if (token) {
        //seteamos que esta autenticado
        this.auth = true;
        //token de autorizacion peticiones
        this.$http.defaults.headers["Authorization"] = token;
        //data del usuario
        this.getStudent(this);
      } else {
        this.$router.push("/");
      }
    },
    closeDelete() {
      this.dialogConfirm = false;
    },
    logOut: function() {
      this.$http.get(this.api + "logout").then(({ data }) => {
        if (data.response) {
          this.$http.defaults.headers["Authorization"] = null;
          sessionStorage.removeItem("language_student_token");
          this.$router.push("/");
        }
      });
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
      token: "Student/getToken",
      user: "Student/getStudent",
      userType: "getUserType",
      core: "getCoreURL",
      coreImg: "getCoreImg",
    }),
  },
};
</script>

<style>
.avatar-content {
  margin: 0 auto;
}
.v-list-item__title {
  cursor: pointer;
}
</style>
