<template>
  <v-snackbar
    :timeout="snackbar.timeout"
    :value="snackbar.isOpen"
    :color="snackbar.color"
    right
    top
  >
    <span :class="snackbar.colorText + '--text'">
      <v-icon :color="snackbar.colorText">
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.message }}
    </span>
    <template v-slot:action="{ attrs }">
      <v-icon
        :color="snackbar.colorText"
        v-bind="attrs"
        @click="closeSnackbar()"
      >
        mdi-close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Snackbar",
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
      closeSnackbar: "Snackbar/closeSnackbar",
    }),
  },
  computed: {
    ...mapGetters({
      snackbar: "Snackbar/getSnackbar",
    }),
  },
};
</script>
