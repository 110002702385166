export default {
  namespaced: true,
  state: {
    isOpen: false,
    type: 'default',
    timeout: 5000,
    icon: '',
    message: '',
    colorText: 'white',
  },
  getters: {
    getSnackbar: (state) => {
      return state;
    },
  },
  mutations: {
    setSnackbar(state, options) {
      state.isOpen = options.isOpen;
      state.type = options.type;
      state.color = options.color;
      state.colorText = options.colorText;
      state.timeout = options.timeout;
      state.icon = options.icon;
      state.message = options.message;

      window.setTimeout(() => {
        state.isOpen = false;
      }, options.timeout);
    },
  },
  actions: {
    openSnackbar({ commit }, props) {
      let icon = '';
      let color = '';
      let colorText = 'white';
      if (props.type == 'success') {
        icon = 'mdi-check';
        color = 'success';
      } else if (props.type == 'info') {
        icon = 'mdi-information-outline';
        color = 'info';
      } else if (props.type == 'warning') {
        icon = 'mdi-exclamation-thick';
        color = 'amber';
        colorText = 'black';
      } else if (props.type == 'danger') {
        icon = 'mdi-window-close';
        color = 'red';
      }
      if (typeof props.message == 'object') {
        let obj = props.message;
        let keys = Object.keys(obj);
        let firstkey = keys[0];
        props.message = obj[firstkey][0];
      }
      commit(
        'Snackbar/setSnackbar',
        {
          isOpen: true,
          type: props.type,
          icon: icon,
          color: color,
          colorText: colorText,
          message: props.message,
          timeout: 5000,
        },
        {
          root: true,
        },
      );
    },
    closeSnackbar({ commit }) {
      commit('setSnackbar', {
        isOpen: false,
      });
    },
  },
};
