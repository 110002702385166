<template>
  <v-app v-if="auth">
    <!-- AppBar -->
    <v-app-bar app color="amarillo" flat fixed clipped-left>
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="pl-0">
        <router-link to="/private/home">
          <v-img src="@/assets/logotipo-negro.png" width="175"></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="text-lowercase black--text"
            v-bind="attrs"
            v-on="on"
          >
            {{ user.email }}
            <v-icon right>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Tema Oscuro</v-list-item-title>
            <v-list-item-action>
              <v-switch v-model="$vuetify.theme.dark" color="blue"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="dialogConfirm = true">
            <v-list-item-title>Volver a Core</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Manu Drawer -->
    <v-navigation-drawer v-model="sidebarMenu" dark color="azul" app clipped>
      <v-list>
        <v-list-item>
          <v-avatar size="96" class="avatar-content">
            <v-img class="text-center" :src="coreImg + 'user/' + user.photo" />
          </v-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title text-center">
              {{ user.first_name }}
              <br />
              {{ user.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-center">
              {{ userType.user_type }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="(item, i) in itemsAccess"
            :key="i"
            link
            :to="item.href"
            color="white"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.text"
                class=""
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-subheader>Catálogos</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(catalog, i) in catalogsAccess"
            :key="i"
            link
            :to="catalog.href"
          >
            <v-list-item-icon>
              <v-icon v-text="catalog.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="catalog.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Content -->
    <v-main>
      <transition name="fade">
        <router-view></router-view>
      </transition>

      <Snackbard />

      <v-dialog v-model="dialogConfirm" max-width="270px">
        <v-card>
          <v-card-title class="tex-center">
            <v-icon color="primary" left>mdi-progress-question</v-icon>
            Información
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mb-0 mt-5 black--text">¿Desea volver a Core?</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete()">
              Cancelar
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="logOut()"
              :loading="isLoading"
              >Volver a Core</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>

    <!-- Footer -->
    <v-footer>
      <Copyright />
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Snackbard from "../components/Snackbar";
import Copyright from "../components/Copyright";
export default {
  components: { Snackbard, Copyright },
  name: "Dashboard",
  data: () => ({
    sidebarMenu: true,
    dialogConfirm: false,
    isLoading: false,
    selectedItem: 0,
    items: [
      {
        text: "Inicio",
        href: "/private/home",
        icon: "mdi-home",
        access: "Inicio",
      },
      {
        text: "Cursos",
        href: "/private/course",
        icon: "mdi-chair-school",
        access: "Cursos",
      },
      {
        text: "Personal",
        href: "/private/personal",
        icon: "mdi-account-group",
        access: "Personal",
      },
      {
        text: "Estudiantes",
        href: "/private/student",
        icon: "mdi-school",
        access: "Estudiantes",
      },
      {
        text: "Caja Registradora",
        href: "/private/cash_register",
        icon: "mdi-cash-register",
        access: "Caja Registradora",
      },
      {
        text: "Evaluaciones diagnósticas",
        href: "/private/diagnostic-results",
        icon: "mdi-clipboard-text-search",
        access: "Evaluaciones diagnósticas",
      },
      {
        text: "Contabilidad",
        href: "/private/accounting",
        icon: "mdi-book",
        access: "Historial Pagos",
      },
      {
        text: "Pagos de Estudiantes",
        href: "/private/pay-students-status",
        icon: "mdi-credit-card-settings-outline",
        access: "Estado de Pagos",
      },
      {
        text: "Pagos Ciclos",
        href: "/private/cycles_payment",
        icon: "mdi-credit-card-settings-outline",
        access: "Estado de Pagos",
      },
      // {
      //   text: 'Mis cursos',
      //   href: '/private/my_courses',
      //   icon: 'mdi-chair-school',
      // },
    ],
    catalogs: [
      {
        text: "Idiomas",
        href: "/private/languages",
        icon: "mdi-flag",
        access: "Idiomas",
      },
      {
        text: "Ciclos",
        href: "/private/cycle",
        icon: "mdi-calendar-range",
        access: "Ciclos",
      },
      {
        text: "Horarios",
        href: "/private/schedule",
        icon: "mdi-clock-outline",
        access: "Horarios",
      },
      {
        text: "Libros",
        href: "/private/book",
        icon: "mdi-book-open-variant",
        access: "Libros",
      },
      {
        text: "Programas",
        href: "/private/program",
        icon: "mdi-format-list-bulleted",
        access: "Programas",
      },
      {
        text: "Salones de Clase",
        href: "/private/classroom",
        icon: "mdi-google-classroom",
        access: "Aulas",
      },
      {
        text: "Tipo de Documento",
        href: "/private/type_document",
        icon: "mdi-file-document-outline",
        access: "Tipos de documento",
      },
      {
        text: "Salarios",
        href: "/private/payment",
        icon: "mdi-cash",
        access: "Salario",
      },
    ],
  }),
  mounted() {
    this.welcome();
  },
  methods: {
    ...mapActions({
      getUser: "getUser",
      getUserType: "getUserType",
      getPermissions: "getPermissions",
      getActionsPermissions: "getActionsPermissions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    welcome: function() {
      let token = undefined;
      if (this.$route.params.token && this.$route.params.token.length > 20) {
        token = this.$route.params.token;
        this.$store.commit("setToken", token);
        sessionStorage.setItem("language_token", token);
        this.$router.push("/private/home");
      } else {
        //aqui deberia pedir ver si el token que tengo en local sesion storage existe en usuarios.
        if (!this.$store.token) {
          token = sessionStorage.getItem("language_token");
          if (token) {
            this.$store.commit("setToken", token);
          }
        }
      }
      if (token) {
        //token de autorizacion peticiones
        this.$http.defaults.headers["Authorization"] = token;
        //data del usuario
        this.getUser(this);
        //permisos
        this.getUserType(this);
        this.getPermissions(this);
        this.getActionsPermissions(this);
      } else {
        window.location = "http://core.ricaldone.edu.sv/";
      }
    },
    closeDelete() {
      this.dialogConfirm = false;
    },
    logOut: function() {
      window.location = this.core + "/" + this.token;
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  computed: {
    ...mapGetters({
      token: "getToken",
      auth: "getAuth",
      user: "getUser",
      userType: "getUserType",
      core: "getCoreURL",
      coreImg: "getCoreImg",
      permissions: "getPermissions",
    }),
    //
    itemsAccess() {
      let items = [];
      this.items.forEach((item) => {
        this.permissions.forEach((permission) => {
          if (this.searchInArray(permission.permission, item.access)) {
            items.push(item);
          }
        });
      });
      return items;
    },
    catalogsAccess() {
      let items = [];
      this.catalogs.forEach((item) => {
        this.permissions.forEach((permission) => {
          if (this.searchInArray(permission.permission, item.access)) {
            items.push(item);
          }
        });
      });
      return items;
    },
  },
};
</script>

<style>
.avatar-content {
  margin: 0 auto;
}
.v-list-item__title {
  cursor: pointer;
}
</style>
