<template>
  <div class="w-100 pt-1">
    <p class="body-2 text--black text-right mb-1">
      Copyright &copy; | Sistema Realizado por el Departamento de Desarrollo de
      Software de el
      <a target="blank_" href="https://www.ricaldone.edu.sv">
        Instituto Técnico Ricaldone
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<style>
.w-100 {
  width: 100%;
}
</style>
