<template>
  <v-app>
    <v-row class="main-register">
      <v-col cols="12">
        <div class="d-flex flex-column justify-center align-center mt-5">
          <!--++++++++ Stepper ++++++++ -->
          <v-stepper v-if="!finish" vertical v-model="e1" class="register">
            <p class="text-center mt-4 blue-grey--text text--darken-4">
              <span class="font-weight-bold text-h4">Registro</span> <br />
              <span class="font-weight-medium text-h5"
                >Escuela de Idiomas Ricaldone</span
              >
            </p>
            <!--********** PRIMER PASO ******** -->
            <v-stepper-step :complete="e1 > 1" step="1">
              Datos Generales
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-row class="mt-1">
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Nombres"
                    required
                    outlined
                    hide-details
                    v-model="student.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Apellidos"
                    required
                    outlined
                    hide-details
                    v-model="student.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value="student.birth_date"
                    transition="scale-transition"
                    offset-y
                    min-width="250px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="student.birth_date"
                        label="Fecha de Nacimiento"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        clearable
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="student.birth_date"
                      :active-picker.sync="activePicker"
                      :max="minAge"
                      :min="maxAge"
                      @change="calculateAge"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    label="Género"
                    :items="genders"
                    item-text="gender"
                    item-value="id_gender"
                    required
                    outlined
                    hide-details
                    v-model="student.id_gender"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Teléfono"
                    required
                    outlined
                    hide-details
                    v-model="student.phone"
                    v-mask="'####-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Correo electrónico"
                    required
                    outlined
                    hide-details
                    v-model="student.email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Departamento"
                    :items="departments"
                    item-text="department"
                    item-value="id_department"
                    required
                    outlined
                    hide-details
                    v-model="student.id_department"
                    @change="getMunicipalities"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    :disabled="!student.id_department"
                    label="Municipio"
                    :items="municipalities"
                    item-text="municipality"
                    item-value="id_municipality"
                    required
                    outlined
                    hide-details
                    v-model="student.id_municipality"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    label="Dirección"
                    required
                    outlined
                    rows="1"
                    auto-grow
                    hide-details
                    v-model="student.address"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <p class="text-center black--text caption mb-0">
                    <v-icon color="primary" left> mdi-information </v-icon>
                    Favor digitar correctamente el correo electrónico.
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <v-btn color="primary" @click="step1()">
                    Continuar
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!--********** SEGUNDO PASO ******** -->
            <v-stepper-step :complete="e1 > 2" step="2">
              Información Académica
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-row>
                <v-col cols="12" md="12">
                  <v-radio-group row v-model="isStuding">
                    <template v-slot:label>
                      <div><strong>¿Está estudiando actualmente?</strong></div>
                    </template>
                    <v-radio label="Sí" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="isStuding">
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Departamento de la institución educativa"
                    :items="sch_departments"
                    item-text="department"
                    item-value="id_department"
                    required
                    outlined
                    hide-details
                    v-model="student.id_department_school"
                    @change="getSchMunicipalities"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Municipio de la institución educativa"
                    :items="sch_municipalities"
                    item-text="municipality"
                    item-value="id_municipality"
                    required
                    outlined
                    hide-details
                    v-model="student.id_municipality_school"
                    @change="getSchools"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="isStuding">
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Grado Académico"
                    :items="degrees"
                    item-text="degree"
                    item-value="id_degree"
                    required
                    outlined
                    hide-details
                    v-model="student.id_degree"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    label="Institución Educativa"
                    :items="schools"
                    item-text="school"
                    item-value="id_school"
                    required
                    outlined
                    hide-details
                    v-model="student.id_school"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    label="Programa que desea inscribir"
                    :items="languages"
                    item-text="language"
                    item-value="id_language"
                    required
                    outlined
                    hide-details
                    v-model="student.id_language"
                    @change="selectedProgramModalities()"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    :disabled="!student.id_language"
                    label="Modalidad de estudio"
                    :items="study_modalities"
                    item-text="modality"
                    item-value="value"
                    required
                    outlined
                    hide-details
                    v-model="student.study_modality"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <v-btn text @click="e1 = 1">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Regresar
                  </v-btn>
                  <v-btn color="primary" @click="step2()">
                    Continuar
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!--********** TERCER PASO ******** -->
            <v-stepper-step :complete="e1 > 3" step="3" v-if="!isAdult">
              Responsables
            </v-stepper-step>
            <v-stepper-content step="3" v-if="!isAdult">
              <p class="body-1 font-weight-medium">
                Por favor ingrese el/los responsables del alumno a registrarse
              </p>
              <h5 class="mt-2">
                <v-icon left> mdi-account </v-icon>
                Responsable #1
                <v-chip small color="primary"> Obligatorio </v-chip>
              </h5>
              <v-row class="mt-1">
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Nombres"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[0].first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Apellidos"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[0].last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    label="Parentesco"
                    :items="relationships"
                    item-text="kin"
                    item-value="id_kin"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[0].id_kin"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Teléfono Celular"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[0].cellphone"
                    v-mask="'####-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Teléfono de Casa"
                    type="text"
                    outlined
                    hide-details
                    v-model="student.responsables[0].phone"
                    v-mask="'####-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Correo Electrónico"
                    type="email"
                    outlined
                    hide-details
                    v-model="student.responsables[0].email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <h5 class="mt-2">
                <v-icon left> mdi-account </v-icon>
                Responsable #2
              </h5>
              <v-row class="mt-1">
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Nombres"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[1].first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Apellidos"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[1].last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    label="Parentesco"
                    :items="relationships"
                    item-text="kin"
                    item-value="id_kin"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[1].id_kin"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Teléfono Celular"
                    required
                    outlined
                    hide-details
                    v-model="student.responsables[1].cellphone"
                    v-mask="'####-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Teléfono de Casa"
                    type="text"
                    outlined
                    hide-details
                    v-model="student.responsables[1].phone"
                    v-mask="'####-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Correo Electrónico"
                    type="email"
                    outlined
                    hide-details
                    v-model="student.responsables[1].email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <v-btn text @click="e1 = 2">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Regresar
                  </v-btn>
                  <v-btn color="primary" class="mr-2" @click="step3()">
                    Continuar
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!--********** CUARTO PASO ******** -->
            <v-stepper-step :step="isAdult ? '3' : '4'">
              Examen de Ubicación
            </v-stepper-step>
            <v-stepper-content step="4">
              <v-row>
                <v-col cols="12">

                  <v-alert
                      v-if="!isAdult"
                      prominent
                      text
                      type="info"
                      border="right"
                    >
                     <span class="font-weight-bold">Declaración de Autorización</span>
                     <br>
                     <span class="text-justify">El padre de familia o responsable del estudiante al finalizar este proceso, autoriza al Instituto Técnico Ricaldone - Escuela de Idiomas, a realizar y utilizar, para fines educativos y de imagen institucional; filmaciones, fotografiado, grabaciones y documentación escrita de entrevistas; comprometiéndose la institución educativa a no lesionar el honor, ni la imagen de los alumnos.</span>
                     </v-alert
                    >

                  <v-alert
                    v-if="isFrenchSelected"
                    border="right"
                    color="orange"
                    prominent
                    text
                    type="info"
                  >
                    El examen de ubicación en nivel personalizado,
                    <span class="font-weight-bold"
                      >momentáneamente no está disponible para el curso de
                      idioma francés</span
                    >.</v-alert
                  >

                  <!-- ingles -->
                  <div v-else>
                    <p class="title">
                      Examen de ubicación en nivel personalizado para
                      <span class="fonr-weight-medium">{{
                        student.program
                      }}</span>
                    </p>
                    <p class="body-1 text-justify">
                      El examen de ubicación es para aquellos estudiantes que
                      tengan algún conocimiento previo del idioma y se sienten
                      capaces de realizarlo, dicha prueba no posee ningun costo
                      adicional.
                    </p>
                    <p class="body-1 text-justify">
                      <span class="font-weight-medium"
                        >¿Que beneficios trae?</span
                      >
                      El examen ayuda al estudiante a empezar en el nivel del
                      idioma que posea, es decir, si tiene un nivel intermedio
                      puede matricularse y comenzar en ese mismo nivel y no
                      iniciar desde cero.
                    </p>
                    <p class="body-1 text-justify">
                      <span class="font-weight-medium"
                        >¿Que sucede si no lo hago?</span
                      >
                      No es obligatorio, si el estudiante no lo realiza
                      automaticamente será ubicado en el nivel 1 del programa.
                    </p>
                  </div>

                  <v-checkbox
                    v-if="!isFrenchSelected"
                    label="Si, deseo realizar el examen de ubicación"
                    v-model="student.exam"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <!-- dependiendo de si el aspirante es mayor de edad, se muestra un boton u otro -->
                  <v-btn
                    text
                    @click="e1 = 2"
                    :disabled="isLoading"
                    v-if="isAdult"
                  >
                    <v-icon left>mdi-chevron-left</v-icon>
                    Regresar
                  </v-btn>
                  <v-btn v-else text @click="e1 = 3" :disabled="isLoading">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Regresar
                  </v-btn>
                  <!-- --------- -->
                  <v-btn color="primary" @click="step4()" :loading="isLoading">
                    Finalizar <v-icon right>mdi-send</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>

          <!-- -----------Cuadro que sale al final del proceso-------------- -->
          <v-row v-else lign="center" justify="center">
            <v-card v-if="message == 1" max-width="500px" max-height="320px">
              <v-col cols="12" md="10" offset-md="1">
                <h1 class="text-center">
                  <v-icon large color="success">mdi-check-circle</v-icon>
                  <br />
                  Exito!
                </h1>
                <h4 class="text-center mb-2">
                  El proceso de registro ha finalizado correctamente!
                </h4>
                <v-divider />
                <p class="text-center mt-2">
                  Se le ha enviado un correo electrónico con usuario y
                  contraseña de acceso a esta plataforma a la dirección
                  <b>
                    {{ this.student.email }}
                  </b>
                  , favor revisar
                  <br />
                  (incluyendo Spam)
                </p>
                <div class="text-center">
                  <v-btn color="primary" to="/">
                    Inicio de Sesión
                    <v-icon right>mdi-login</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-card>
            <v-card v-if="message == 2" max-width="500px" max-height="470px">
              <v-col cols="12" md="10" offset-md="1">
                <h1 class="text-center">
                  <v-icon large color="success">mdi-information</v-icon>
                  <br />
                  Información!
                </h1>
                <v-divider />
                <p class="text-justify mt-2">
                  Escuela de Idiomas Ricaldone tiene mejores opciones para ti,
                  al tener {{ this.student.age }} años de edad, te invitamos a
                  formar parte de los cursos de Ingles que posee la institución
                  en conjunto con
                  <a
                    href="https://www.facebook.com/cfpRicaldone/"
                    target="_blank"
                  >
                    Centro de Formación Profesional Ricaldone
                  </a>
                  los cuales se adecuan mejor a ts necesidades y te dan un
                  beneficio todavia mayor.
                  <br />
                  Comunicate directamente con el Instituto Ricaldone para darte
                  una asesoria personalizada.
                </p>
                <p class="black--text">
                  <v-icon left color="primary"> mdi-email </v-icon>
                  <a href="to:cfp@ricaldone.edu.sv" class="decoration-none">
                    cfp@ricaldone.edu.sv
                  </a>
                </p>
                <p class="black--text">
                  <v-icon left color="primary"> mdi-phone </v-icon>
                  <a href="tel:+503 2234 6077" class="decoration-none">
                    +503 2234 6077
                  </a>
                </p>
                <div class="text-center">
                  <v-btn color="primary" href="https://ricaldone.edu.sv">
                    Web Oficial Ricaldone
                    <v-icon right>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-card>
          </v-row>
          <!-- /////FOOTER///// -->
          <div class="copy mt-5 pa-5">
            <p class="body-2 white--text text-center mb-0">
              Copyright &copy; | Realizado por el Departmento de Desarrollo de
              Software del
              <a
                target="blank_"
                class="white--text"
                href="https://www.ricaldone.edu.sv"
                >Instituto Técnico Ricaldone</a
              >
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <Snackbard />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Snackbard from "../../components/Snackbar";
import moment from "moment";
export default {
  name: "Register",
  components: {
    Snackbard,
  },
  data: () => ({
    e1: 1,
    menu: false,
    student: {
      responsables: [{}, {}],
      exam: false,
    },
    genders: [],
    departments: [],
    municipalities: [],
    relationships: [],

    isFrenchSelected: null,
    isAdult: false,

    sch_departments: [],
    sch_municipalities: [],
    schools: [],
    degrees: [],
    languages: [],
    programs: [],
    minAge: null,
    maxAge: null,
    isLoading: false,
    finish: false,
    message: 1,
    study_modalities: [],
    isStuding: false,
    activePicker: null,
  }),
  mounted() {
    this.getGenders();
    this.getDepartments();
    this.getRelationships();
    this.getDegrees();
    this.getLanguages();
    // this.getPrograms();
    let min_date = moment()
      .subtract(7, "years")
      .format("Y-MM-DD");
    let max_date = moment()
      .subtract(90, "years")
      .format("Y-MM-DD");
    //limites edades
    this.minAge = min_date;
    this.maxAge = max_date;
  },
  methods: {
    //actions
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),

    //funcionamiento
    getLanguages: function() {
      this.$http.get(this.api + "language/all").then(({ data }) => {
        this.languages = data;
      });
    },
    getGenders: function() {
      this.$http.get(this.apiCore + "gender_all").then(({ data }) => {
        this.genders = data;
      });
    },
    getDepartments: function() {
      this.$http.get(this.apiAdmision + "department/all").then(({ data }) => {
        this.departments = data;
        this.sch_departments = data;
      });
    },
    getRelationships: function() {
      this.$http.get(this.apiAdmision + "kin/all").then(({ data }) => {
        this.relationships = data;
      });
    },
    getDegrees: function() {
      this.$http.get(this.apiAis + "degree/all").then(({ data }) => {
        this.degrees = data;
      });
    },
    getMunicipalities: function() {
      let id = this.student.id_department;
      this.$http
        .get(this.apiAdmision + "municipality/department/" + id)
        .then(({ data }) => {
          this.municipalities = data;
        });
    },
    getSchMunicipalities: function() {
      let id = this.student.id_department_school;
      this.$http
        .get(this.apiAdmision + "municipality/department/" + id)
        .then(({ data }) => {
          this.sch_municipalities = data;
        });
    },
    getSchools: function() {
      let id = this.student.id_municipality_school;
      this.$http
        .get(this.apiAdmision + "school/municipality/" + id)
        .then(({ data }) => {
          this.schools = data;
        });
    },
    step1: function() {
      let validate =
        this.student.first_name &&
        this.student.last_name &&
        this.student.birth_date &&
        this.student.id_gender &&
        this.student.phone &&
        this.student.email &&
        this.student.id_municipality &&
        this.student.address;
      if (validate) {
        this.e1++;
      } else {
        this.openSnackbar({
          type: "info",
          message: "Favor completar los campos",
        });
      }
    },
    step2: async function() {
      let validate =
        this.student.id_language && this.student.study_modality != null;

      if (!validate) {
        this.openSnackbar({
          type: "info",
          message: "Favor completar los campos",
        });
        return;
      }

      if (this.student.age >= 18) {
        this.e1 = 4;
      } else {
        this.e1++;
      }

      //getting available programs by language
      try {
        let response = await fetch(
          `${this.api}program/language/${this.student.id_language}`
        );
        this.programs = await response.json();
      } catch (error) {
        console.warning("error al traer los programas. ", error);
      }

      //setting program by age
      let bestSuitedProgramGivenAge = this.programs.filter((program) => {
        return (
          this.student.age >= program.age_start &&
          this.student.age <= program.age_end
        );
      });

      this.student.id_program = bestSuitedProgramGivenAge[0]?.id_program;
      this.student.program = bestSuitedProgramGivenAge[0]?.program;

      //If age it's not suited for any available programs
      if (this.student.id_program == undefined) {
        this.finish = true;
        this.message = 2;
      }
    },

    step3: function() {
      let validate =
        this.student.responsables[0].first_name &&
        this.student.responsables[0].last_name &&
        this.student.responsables[0].id_kin &&
        this.student.responsables[0].cellphone &&
        this.student.responsables[0].phone &&
        this.student.responsables[0].email;
      if (validate) {
        this.e1++;
      } else {
        this.openSnackbar({
          type: "info",
          message:
            "Favor completar los campos de responsables, al menos un responsable.",
        });
      }
    },
    step4: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "student", this.student)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.finish = true;
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    calculateAge: function() {
      let birthday = new Date(this.student.birth_date);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      this.student.age = age;
      if (this.student.age > 17) this.isAdult = true;
      else this.isAdult = false;
    },
    selectedProgramModalities() {
      this.study_modalities = [];
      if (this.student.id_language === 3) {
        this.study_modalities = [
          {
            value: 1,
            modality: "Presencial",
          },
        ];
        this.isFrenchSelected = true;
        return;
      }
      this.isFrenchSelected = false;
      this.study_modalities = [
        {
          value: 0,
          modality: "Virtual",
        },
        {
          value: 1,
          modality: "Presencial",
        },
      ];
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    selectedProgramStatus() {
      return !!this.student.id_language;
    },
    ...mapGetters({
      api: "getApi",
      apiAdmision: "getAdmisionApi",
      apiAis: "getAisApi",
      apiCore: "getCoreApi",
    }),
  },
};
</script>

<style>
.register {
  width: 60%;
}

@media screen and (max-width: 960px) {
  .register {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .register {
    width: 90%;
  }
}

.main-register {
  background-image: url("../../assets/wallpapers-2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
