<template>
  <v-app>
    <v-row class="main-login">
      <v-col cols="12">
        <div class="d-flex flex-column justify-center align-center fill-height">
          <v-card class="w-20">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-img
                    class="align-self-stretch mx-auto"
                    width="225"
                    src="@/assets/logo-color-square.png"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.email"
                    label="Correo Electrónico Institucional"
                    type="text"
                    required
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-at"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.password"
                    label="Contraseña"
                    type="password"
                    class="d-none"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.password"
                    label="Contraseña"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-shield-key"
                    :append-icon="'mdi-eye' + (showPassword ? '' : '-off')"
                    @click:append="togglePassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="10" offset-md="1">
                  <v-btn
                    color="primary"
                    large
                    block
                    @click="logIn()"
                    :loading="isLoading"
                  >
                    Iniciar sesión
                    <v-icon right>mdi-login</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <v-btn text to="/forgot_password" small>
                    ¿Olvidó su contraseña?
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="copy mt-5 pa-4">
            <p class="body-2 white--text text-center mb-0">
              Copyright &copy; Realizado por el Departamento de
              <br />Desarrollo de Software del
              <a
                target="blank_"
                class="white--text"
                href="https://www.ricaldone.edu.sv"
                >Instituto Técnico Ricaldone</a
              >
            </p>
          </div>
        </div>
      </v-col>
      <Snackbar />
    </v-row>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Snackbar from "../../components/Snackbar.vue";
export default {
  name: "Login",
  components: {
    Snackbar,
  },
  data() {
    return {
      isLoading: false,
      form: {},
      showPassword: false,
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    logIn: async function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "login", this.toFormData(this.form))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message ? data.message : data,
          });
          if (data.response) {
            sessionStorage.setItem("language_student_token", data.token);
            this.$http.defaults.headers["Authorization"] = data.token;
            this.$router.push("/portal/home");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    togglePassword: function() {
      this.showPassword = !this.showPassword;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style>
.w-20 {
  width: 500px;
}

@media screen and (max-width: 960px) {
  .w-20 {
    width: 400px;
  }
  .copy {
    width: 400px;
  }
}

.copy {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.main-login {
  background-image: url("../../assets/wallpapers-2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
