import Vue from "vue";
import VueMask from "v-mask";
import Axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import vuetify from "./plugins/vuetify";
import "@/plugins/vue-json-excel";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.all.js";
import Swal from "sweetalert2";

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);

Vue.mixin({
  data() {
    return {};
  },
  methods: {
    searchInArray: (arr, search) => arr.indexOf(search) >= 0,
    toFormData: (obj) => {
      var form_data = new FormData();
      for (var key in obj) {
        form_data.append(key, obj[key]);
      }
      return form_data;
    },
    //pasar esto al mixin, y el mixin pasarlo a un solo archivo no en main.js
    createDate: function(days, months, years, format) {
      let date = new Date();
      date.setDate(date.getDate() + days);
      date.setMonth(date.getMonth() + months);
      date.setFullYear(date.getFullYear() + years);
      switch (format) {
        case "yyyy-mm-dd":
          return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        case "dd/mm/yyyy":
          return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
        case "ddmmyyyy":
          return `${date.getDate()}${date.getMonth()}${date.getFullYear()}`;
        default:
          return date;
      }
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.fireToast({
          icon: "success",
          title: "Texto copiado en el portapapeles",
        });
      } catch (err) {
        this.fireToast({
          icon: "error",
          title: "No ha sido posible copiar el texto",
        });
        console.error(err);
      }
    },
    fireToast({ icon, title, timer = 3500 }) {
      Swal.fire({
        icon,
        title,
        toast: true,
        showConfirmButton: false,
        timer,
        position: "top-end",
        timerProgressBar: true,
      });
    },
    formatFullDate(str) {
      const date = new Date(str);
      const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formatDate = date.toLocaleDateString("es-SV", dateOptions);
      const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
      const formateTime = date.toLocaleTimeString("es-SV", timeOptions);
      return `${formatDate} -  ${formateTime}`;
    }
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
