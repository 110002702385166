<template>
  <v-app>
    <v-row class="main-not-found d-flex justify-center align-center">
      <v-col cols="12" xs="8" sm="6" md="4">
        <v-card class="card-not-found" elevation="20">
          <v-card-text class="text-center pa-10">
            <p class="display-4 black--text font-weight-medium mb-0">404</p>
            <p class="title text--primary">Error - Página no Encontrada</p>
            <v-divider class="my-5 mx-auto" style="width: 60%"></v-divider>
            <p class="subtitle-1 text--secondary">
              La página que está buscando podría haber sido eliminada si se
              cambió su nombre o no está disponible temporalmente
            </p>
            <v-btn
              to="/portal/home"
              color="naranja"
              elevation="3"
              large
              class="white--text"
            >
              Ir al Inicio
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style>
.main-not-found {
  background-image: url("../assets/wallpapers-1.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.card-not-found {
  width: 100%;
}
</style>
