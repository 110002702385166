export default {
  namespaced: true,
  state: {
    token: '',
    student: {},
  },
  getters: {
    getStudent: (state) => state.student,
    getToken: (state) => state.token,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setStudent(state, student) {
      state.student = student;
    },
  },
  actions: {
    getStudent: async function({
      commit
    }, vm) {
      const {
        data
      } = await vm.$http.get(vm.api + 'student/my_info');
      if (data.response == false) {
        vm.$router.push('/');
      }
      if (data.photo) {
        data.photo_img = data.photo;
        data.photo = null;
      }
      commit('setStudent', data);
    },
  },
};