<template>
  <v-app>
    <v-row class="main-login">
      <v-col cols="12">
        <div class="d-flex flex-column justify-center align-center fill-height">
          <v-card class="w-20">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-img
                    class="align-self-stretch mx-auto"
                    width="225"
                    src="@/assets/logo-color-square.png"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row v-if="!finish">
                <v-col cols="12">
                  <v-text-field
                    label="Correo electrónico institucional"
                    type="text"
                    required
                    outlined
                    hide-details
                    prepend-inner-icon="mdi-at"
                    v-model="form.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!finish">
                <v-col cols="12" sm="12" md="10" offset-md="1">
                  <v-btn
                    color="primary"
                    large
                    block
                    @click="forgotPassword()"
                    :loading="isLoading"
                  >
                    Recuperar Clave
                    <v-icon right>mdi-send</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="finish">
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-icon color="success" large>mdi-check-circle</v-icon>
                </v-col>
                <v-col cols="12" sm="12" md="10" offset-md="1">
                  <p class="black--text text-justify">
                    Ha sido enviado un correo eletrónico a la dirección:
                    <b>{{ sentTo }}</b> , con las credenciales para acceder
                    nuevamente a su cuenta. <br /><br />
                    Favor revisar la bandeja del correo electrónico, incluyendo
                    Spam.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <v-btn outlined to="/" small>
                    <v-icon>mdi-arrow-left</v-icon> Volver a Iniciar Sesión
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="copy mt-5 pa-4">
            <p class="body-2 white--text text-center mb-0">
              Copyright &copy; Realizado por el Departamento de
              <br />Desarrollo de Software del
              <a
                target="blank_"
                class="white--text"
                href="https://www.ricaldone.edu.sv"
                >Instituto Técnico Ricaldone</a
              >
            </p>
          </div>
        </div>
      </v-col>
      <Snackbar />
    </v-row>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Snackbar from "../../components/Snackbar.vue";
export default {
  name: "ForgotPassword",
  components: {
    Snackbar,
  },
  data() {
    return {
      finish: false,
      isLoading: false,
      form: {},
      sentTo: null,
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    forgotPassword: async function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "recovery_password", this.toFormData(this.form))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message ? data.message : data,
          });
          if (data.response) {
            this.finish = true;
            this.sentTo = data.data.sent_to;
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style>
.copy {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.main-login {
  background-image: url("../../assets/wallpapers-2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
